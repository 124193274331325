import React from 'react';

import imgLanding from '../../assets/img/home/home-1200w.jpg';
import imgSignUpLanding from '../../assets/img/home/hello-people.jpg';

import AddressSearch from '../AddressSearch/AddressSearch';
import { Link } from 'gatsby';

const LandingSection = () => {
  return (
    <section className="container mb-10 section-landing">
      <div className="row">
        <div className="col-7">
          <h1 className="roboto mt-12 mt-6-md pl-20 pl-6-md pr-6-md mb-2 font-size-xxl font-size-xl-md">
            Rejoignez vos voisins !
          </h1>
          <div className="muli font-size-xl pl-20 pl-6-md pr-6-md font-size-lg-md">
            Alacaza est la première application dédiée à la vie de votre
            résidence et de votre quartier
          </div>

          <div className="mt-10 hide-overflow-md">
            <img
              src={imgLanding}
              alt="Alacaza - Rejoignez vos voisins !"
              className="landing-img oversize-md size-100"
            />
          </div>
        </div>

        <div className="col-5 col-12-sm">
          <div className="pl-15 pl-6-lg pr-10 pl-6-md pr-6-md">
            <div className="mt-21 pl-5 text-align-right hide-md">
              <img
                src={imgSignUpLanding}
                alt="Alacaza - Rejoignez vos voisins !"
                className="hide-md size-100"
                style={{ maxWidth: `280px` }}
              />
            </div>

            <div className="roboto font-size-xl bold mt-5 pl-0-md text-align-right text-align-left-md">
              Démarrez votre inscription
            </div>
            <div className="muli font-size-md2 pt-1 pl-0-md text-align-right text-align-left-md pb-3">
              Renseignez votre adresse postale
              <br />
              et commencez à rejoindre vos voisins
            </div>

            <div className="search-container">
              <AddressSearch
                id="addressSearch"
                zoneId={null}
                placeholder="Mon adresse postale"
              />
              <Link
                className="block float-right size-100 btn regular mt-2 -hide -show-md text-align-center"
                to="/inscription/"
              >
                <div className="font-size-lg-plus pt-1 pb-1">Je m'inscris</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingSection;
