import React from 'react';
import imgUsageChat from '../../assets/img/icons/usage-chat.svg';
import imgUsageMarket from '../../assets/img/icons/usage-market.svg';
import imgUsageNewsFeed from '../../assets/img/icons/usage-newsfeed.svg';
import imgUsageDirectory from '../../assets/img/icons/usage-directory.svg';
import imgUsageLostAndFound from '../../assets/img/icons/usage-lostfound.svg';
import imgUsageSecurity from '../../assets/img/icons/usage-lock.svg';
import imgUsageRecommendation from '../../assets/img/icons/usage-recommendation.svg';
import imgUsageEvent from '../../assets/img/icons/usage-event.svg';
import imgUsagePoll from '../../assets/img/icons/usage-poll.svg';

import imgMobile from '../../assets/img/home/illu-usages.png';

const UsagesSection = () => {
  const usages = [
    {
      icon: imgUsageChat,
      title: 'Messagerie',
      description: 'Communiquez facilement',
    },
    {
      icon: imgUsageMarket,
      title: 'Petites annonces',
      description: 'Échangez vos bien et services localement',
    },
    {
      icon: imgUsageNewsFeed,
      title: "Fil d'actualités",
      description: 'Consultez les nouvelles de votre quartier',
    },
    {
      icon: imgUsageDirectory,
      title: 'Répertoire',
      description: 'Consultez l’annuaire de votre résidence',
    },
    {
      icon: imgUsageLostAndFound,
      title: 'Perdu / Trouvé',
      description: 'Déclarez perdu ou trouvé un objet, un animal, ...',
    },
    {
      icon: imgUsageSecurity,
      title: 'Sécurité',
      description: 'Alertez vos voisins d’un danger ou d’un risque éventuel',
    },
    {
      icon: imgUsageRecommendation,
      title: 'Recommandations',
      description:
        'Demandez conseil à vos voisins (commerce, médecin, restaurant, ...)',
    },
    {
      icon: imgUsageEvent,
      title: 'Événements',
      description:
        'Organisez un événement avec vos voisins (fête des voisins, réunions, ...)',
    },
    {
      icon: imgUsagePoll,
      title: 'Sondages',
      description: 'Créez un sondage pour éclairer une prise de décision',
    },
  ];

  return (
    <section className="mt-10 pt-10 pb-10 section-usages">
      <div className="container">
        <div className="row">
          <div className="col-5 hidden-sm flex justify-content-center align-items-start">
            <img
              src={imgMobile}
              alt="Usages"
              className="hidden-sm max-width-500-lg"
            />
          </div>

          <div className="col-7 hidden-sm">
            <div className="usages-list mt-10 pl-8">
              <ul>
                {usages.map((usage, index) => (
                  <li key={'usage-' + index} className="pb-10 pr-8">
                    <div className="usage-card">
                      <div className="usage-icon">
                        <img src={usage.icon} alt="{usage.title}" />
                      </div>
                      <div className="bold font-size-lg pt-3">
                        {usage.title}
                      </div>
                      <div className="text-color-purple-grey font-size-md pt-2">
                        {usage.description}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-12 hide show-md">
            <div className="usages-cards-container mb-10">
              {usages.map((usage, index) => (
                <div
                  key={'usage-' + index}
                  className="card pt-8 pl-3 pr-3 text-align-center"
                >
                  <div className="usage-icon">
                    <img src={usage.icon} alt={usage.title} />
                  </div>
                  <div className="bold font-size-md pt-3">{usage.title}</div>
                  <div className="text-color-purple-grey font-size-md pt-2">
                    {usage.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UsagesSection;
