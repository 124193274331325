import React from 'react';
import imgPrivacyFree from '../../assets/img/icons/privacy-free.svg';
import imgPrivacyIdentity from '../../assets/img/icons/privacy-identity.svg';
import imgPrivacyLock from '../../assets/img/icons/privacy-lock.svg';

const PrivacySection = () => {
  return (
    <section className="container section-privacy mt-20 mt-10-md">
      <ul className="text-align-center">
        <li className="pb-5">
          <img src={imgPrivacyFree} alt="Gratuit" className="privacy-icon" />
          <div className="bold font-size-lg">Gratuit</div>
        </li>
        <li className="pb-5">
          <img
            src={imgPrivacyLock}
            alt="Données protégées"
            className="privacy-icon"
          />
          <div className="bold font-size-lg">Données protégées</div>
        </li>
        <li>
          <img
            src={imgPrivacyIdentity}
            alt="Identités vérifiées"
            className="privacy-icon"
          />
          <div className="bold font-size-lg">Identités vérifiées</div>
        </li>
      </ul>
    </section>
  );
};

export default PrivacySection;
