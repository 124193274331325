import React from 'react';
import { withPrefix } from 'gatsby';

import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import imgBgArea from '../../assets/img/home/bg-area.png';
import iconEvent from '../../assets/img/icons/newsfeed-event.svg';
import iconGeneral from '../../assets/img/icons/newsfeed-general.svg';
import iconLostFound from '../../assets/img/icons/newsfeed-lostfound.svg';
import iconPoll from '../../assets/img/icons/newsfeed-poll.svg';

SwiperCore.use([Pagination, Autoplay]);

const NeighborhoodSection = () => {
  const monthNames = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];

  const newsFeedPosts = [
    {
      icon: iconGeneral,
      title: 'Couture solidaire',
      category: 'Publication générale',
      message:
        'Je vous propose de vous initier à la couture au cours de séances gratuites entre résidents de tout âge. Nous confectionnerons des...',
    },
    {
      icon: iconPoll,
      title: 'Usage du jardin partagé',
      category: 'Sondage',
      message:
        "Des zones du jardin partagé ne sont plus utilisées depuis quelques semaines. Que pensez-vous d'en dédier certaines à la...",
    },
    {
      icon: iconLostFound,
      title: 'Clés trouvées devant le local',
      category: 'Perdu / trouvé',
      message:
        'Nous avons trouvé un jeu de clés devant le local à vélo. Contactez-nous en nous décrivant le porte-clés pour les récupérer :)',
    },
    {
      icon: iconEvent,
      title: 'Fête de quartier',
      category: 'Événement',
      message:
        'Une fête de quartier est organisée le dernier week-end de ' +
        monthNames[new Date().getMonth()] +
        ". Des stands d'association en partenariat avec la mairie seront...",
    },
  ];

  return (
    <section className="container section-neighborhood mt-10 mt-2-md">
      <div className="row">
        <div className="col-6 pl-20 pl-6-md pr-6-md">
          <div className="roboto font-size-xl font-size-xl-md pt-10 bold">
            ... Et à votre quartier
          </div>
          <div className="mt-10 text-color-purple-grey font-size-md size-75 size-100-md">
            <p>
              Participez à l'animation locale et redonnez vie à votre quartier :
            </p>

            <ul className="bullets-list pl-5">
              <li className="pb-2">
                <strong>Découvrez</strong> les actualités de votre quartier
              </li>
              <li className="pb-2">
                <strong>Partagez</strong> des recommandations et organiser des
                événements
              </li>
              <li>
                <strong>Échangez</strong> des biens et services sur une place de
                marché locale
              </li>
            </ul>
          </div>
        </div>
        <div className="col-6 pr-25 pl-25 pb-5 pt-5-md pl-5-md pr-5-md">
          <div
            className="newsfeed-examples pt-5"
            style={{ backgroundImage: `url(${withPrefix(imgBgArea)})` }}
          >
            <div className="newsfeed-posts pt-2 pb-5">
              <Swiper
                slidesPerView={1}
                pagination={{ el: '.steps-list', clickable: true }}
                autoplay
                loop
              >
                {newsFeedPosts.map((newsFeedPostData, index) => (
                  <SwiperSlide key={`slide-${index}`}>
                    <div
                      key={'newsfeed-posts-sample-' + index}
                      className="newsfeed-post mt-3 mr-3 ml-3 mb-3"
                    >
                      <div className="newsfeed-post-header">
                        <div className="newsfeed-post-icon inline pl-4">
                          <img
                            src={newsFeedPostData.icon}
                            alt={newsFeedPostData.category}
                          />
                        </div>
                        <div className="newsfeed-post-title inline pl-3">
                          <div className="bold font-size-md2">
                            {newsFeedPostData.title}
                          </div>
                          <div className="font-size-xs">
                            {newsFeedPostData.category}
                          </div>
                        </div>
                      </div>
                      <div className="roboto font-size-md text-color-purple-grey pt-4 pr-4 pl-4 pt-4">
                        {newsFeedPostData.message}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                <div className="steps-list home-post-steps" />
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NeighborhoodSection;
