import React from 'react';
import { withPrefix } from 'gatsby';

import imgDiscussion1 from '../../assets/img/home/illu-my-property.png';
import imgBg from '../../assets/img/home/bg-wave.png';

const PropertySection = () => {
  return (
    <section
      className="pt-10 section-property bg-wave"
      style={{ backgroundImage: `url(${withPrefix(imgBg)})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-6 pr-20 pl-20 hidden-sm">
            <img
              src={imgDiscussion1}
              alt="Un espace dédié à votre résidence"
              className="max-width-400-lg max-width-450-xl"
            />

            <div className="steps-list pt-1 hide">
              <ul>
                <li className="selected">
                  <span></span>
                </li>
                <li>
                  <span></span>
                </li>
                <li>
                  <span></span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6 pl-6-md pr-6-md">
            <div className="roboto font-size-xl bold pt-10 pt-0-md font-size-xl-md">
              Un espace dédié à votre résidence...
            </div>
            <div className="mt-10 text-color-purple-grey font-size-md size-75 size-100-md">
              <p>Participez aux groupes de discussions de votre résidence :</p>

              <ul className="bullets-list pl-5">
                <li className="pb-2">
                  <strong>Contactez</strong> plus facilement vos voisins
                </li>
                <li className="pb-2">
                  <strong>Signalez et suivez</strong> les incidents et travaux
                </li>
                <li>
                  <strong>Accédez</strong> à toutes les informations concernant
                  votre lieu de vie
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row hide show-md">
          <div className="col-12 pl-6-md pr-6-md text-align-center">
            <img src={imgDiscussion1} alt="Un espace dédié à votre résidence" />

            <div className="steps-list pt-1 hide">
              <ul>
                <li className="selected">
                  <span></span>
                </li>
                <li>
                  <span></span>
                </li>
                <li>
                  <span></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertySection;
