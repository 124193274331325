import React from 'react';
import { Link } from 'gatsby';
import { MainLayout } from '../components/Layout';

import LandingSection from '../components/Home/LandingSection';
import PropertySection from '../components/Home/PropertySection';
import NeighborhoodSection from '../components/Home/NeighborhoodSection';
import UsagesSection from '../components/Home/UsagesSection';
import PrivacySection from '../components/Home/PrivacySection';

const App = () => {
  return (
    <MainLayout
      siteTitle="Rejoignez vos voisins sur Alacaza"
      siteDescription=""
    >
      <LandingSection />

      <div>
        <PropertySection />
        <NeighborhoodSection />
        <UsagesSection />
      </div>

      <PrivacySection />

      <section className="container mt-10 mb-5 section-landing-partners pl-3-md pr-3-md text-align-center">
        <div className="box p-12 pt-6-md pb-6-md pr-5-md pl-5-md text-align-center ml-auto mr-auto max-width-720-lg">
          <div className="roboto bold font-size-xxl font-size-lg-plus-md">
            Vous êtes un professionnel ?
          </div>
          <div className="font-size-lg-plus font-size-md-md pt-7">
            Syndic, bailleur social, collectivité territoriale, promoteur,
            opérateur urbain, association, commerçant...
          </div>
          <div className="pt-10">
            <Link to={'/professionnels/'} className="btn cta-pro">
              Découvrez nos offres
            </Link>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

// Index is wrapped by <Provider store={store}>
// defined in ../../wrap-with-provider.js
export default App;
